.createAppointment {
  display: flex;
  padding: 20px 0;
  border-radius: 10px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;

  /* padding-bottom: 30px; */

}

.chooseText {
  color: #939393;
  font-size: 14px;
  line-height: 0.8;
  font-family: ProximaNova-Semibold;
}

.chooseContent {
  color: #000000;
  font-size: 16px;
  font-family: ProximaNova-Semibold;
  text-transform: capitalize;
}

.apptCategory {
  min-height: 650px;
  width: 75%;
  margin: 0 7px;
  /* overflow-y: visible; */
  overflow-x: hidden;

  overflow-y: hidden;
  /* height: 587px; */
  /* min-height: 100%; */
  border-radius: 10px;
}

.createApptHead {
  position: relative;
  height: 70px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.11);
  background-color: #ffffff;
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #3b3b3b;
  font-family: ProximaNova-Semibold;
  padding-left: 25px;
  z-index: 11;
}

.createApptHead img {
  padding: 0 20px;
}

.createApptHead img:hover {
  cursor: pointer;
}

.resModal .modal-content {
  border-radius: 28px !important;
}

.rescModal {
  text-align: center;
  padding: 10px 5px;
}

.modFooter {
  justify-content: center;
}

.modHead {
  font-family: ProximaNova-SemiBold;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 0.83;
  letter-spacing: 1.22px;
  padding: 15px 0;
  color: #2d414a;
}

.modContent {
  font-family: ProximaNova-Regular;
  font-size: 14px;
  line-height: 1.29;
  color: #2d414a;
}

.modLink {
  display: inline-block;
  font-family: ProximaNova-Semibold;
  font-size: 14px;
  line-height: 1.21;
  text-align: center;
  color: #2886af;
  margin: 0 10px;
}

.modButton {
  display: inline-block;
  background-color: #2886af;
  color: #fff;
  font-size: 14px;
  line-height: 1;
  font-family: ProximaNova-SemiBold;
  padding: 15px 25px;
  border-radius: 25px;
  margin: 0 10px;
}

.modButton:hover,
.modLink:hover {
  cursor: pointer;
}

.modSlotTime {
  color: #37b1e6;
  text-transform: uppercase;
}

/* responsiveness */

/* @media screen and (max-width: 480px) {
  .createAppointment {
    display: flex;
    flex-direction: column;
  }
  .apptCategory {
    height: 600px;
  }
} */