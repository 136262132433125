/*Doctor Card*/

.doctorCardContainer {
  width: 11rem;
  border: 1px solid #cecece;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  margin: 10px;
  text-transform: capitalize;
  /* justify-content: center;
    align-items: center; */
}

.doctorCardBlur {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  border-radius: 10px;
  z-index: -1;
}

.doctorCardContainer:hover {
  cursor: pointer;
  box-shadow: 0px 18px 45px #0000003b;
  border: none;
}

.doctorCardContainer:hover .doctorCardBlur {
  /* background: url('../../../../assets/images/doctor_photo/doctor_photo_square.png')  no-repeat; */
  background: #2886af;
  background-size: 133%;
  background-position: center;
  /* filter: blur(11px);
    -webkit-filter: blur(11px); */
  z-index: 5;
  transition: all 1s linear;
}

/* .doctorCardContainer:hover .doctorPhotoSection{
    background-color:black !important;
} */

/* .doctorCardContainer:hover .doctorCardSeperator{
    background: aliceblue;
} */
.doctorCardContainer:hover .doctorName,
.doctorCardContainer:hover .doctorSpec {
  color: #fff;
}

.doctorCardMainContent {
  z-index: 10;
}
/* Styling doctor photo section*/

.doctorPhotoSection {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 68px;
  height: 68px;
  border-radius: 50%;
  background: aliceblue;
  margin: 23px auto;
  font-family: ProximaNova-Bold;
  font-size: 24px;
  color: #fff;
  overflow: hidden;
}

.doctorPhotoSection img {
  /* max-width: 100%; */
  width: 68px;
  height: 68px;
}

/* Styling doctor section seperator */

.doctorCardSeperator {
  background: #cecece;
  height: 1px;
  width: 100%;
}

/* Styling doctor card content */

.doctorCardDetails {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
}

/* Styling doctor name */

.doctorName {
  font-family: ProximaNova-Semibold;
  font-size: 17px;
  color: #000;
  line-height: 1.5;
}

/* Styling doctor specialization */

.doctorSpec {
  color: #939393;
  font-size: 14px;
  font-family: ProximaNova-Semibold;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
