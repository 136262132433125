.timeCategory{
    font-family: ProximaNova-Semibold;
    font-size: 14px;
    padding: 0 13px;
    color: #000;
  }
.activeTime{
    background:url('../../assets/images/icons/checkmark.svg') no-repeat;
    background-position:left;
    padding:0 17px;
    color:#2886af;
    background-size:10px 10px;
  }

  .seperator{
    height:10px;
    width:1px;
    background-color:#000;
  }



/*Calender Header*/


.calender-header{
  display: flex;
  height: 70px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding:0 24px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.full-date{
  display:flex;
  align-items: center;
}

.date{
  /* float: left; */
  width:40px;
  font-size: 32px;
  font-family: ProximaNova-Bold;
  color: #005c84;
}

.day-month-year{
  width:119px;
  padding: 0 7px;
}

.right-content{
  display:flex;
  align-items: center;
}

.day{
  /* padding: 4px 0; */
  font-size: 14px;
  line-height:0.5;
  text-align: left;
  color: #09163c;
  font-family: ProximaNova-Semibold;
}

.month-year{
  font-size: 14px;
  font-family: ProximaNova-Regular;
  text-align: left;
  color: #808080;
}


.month-wise img{
  height: 9px;
  margin-bottom: 3px;
  margin-right: 4px;
}

.category{
  padding: 0 30px;
  font-size: 12px;    
  font-family: ProximaNova-Semibold;
  text-align: left;
  color: #000000;
}

.switch{
  position: relative;
  display: inline-block;
  width: 62px;
  height: 34px;
  font-size: 13px;
  color: #ffffff;
  text-align: center;
}

.switch input{
  opacity: 0;
  width: 0;
  height: 0;
}

.slider{
  position: absolute;
  cursor: pointer;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before{
  position: absolute;
  content: "IN";
  height: 26px;
  width: 37px;
  left: 4px;
  bottom: 4px;
  background-color: #2886af;
  -webkit-transition: .4s;
  transition: .4s;    
}

input:checked + .slider{
  background-color: #ffffff;
}

input:focus + .slider{
  box-shadow: 0 0 1px #ffffff;
}

input:checked + .slider:before{
  -webkit-transform: translateX(16px);
  transform: translateX(16px);
}

.slider.round{
  border-radius: 34px;
  border: 1px solid #2886af;
}
.slider.round:before{
  border-radius: 25px
}

.dayColumn{
  text-align:center;
  padding:25px 0 10px 0;
  text-transform: uppercase;
  color:#8b8b8b;
  font-family: ProximaNova-Regular;
  font-size: 14px;
}

.dateChangeMenu{
  padding-left:5px;
}
.dateChangeMenu img {
  padding:0 5px;
  margin:0 5px;
}

.dateChangeMenu img:hover{
  cursor: pointer;
  transform: scale(1.3);
}


  