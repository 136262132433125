.deleteScreenContainer{
    margin: 0px;
    padding-top: 10%;
    background:url("https://reception.athancare.com/static/media/LoginBackground.56225909.png");
    }
    .deleteContainer{
    width: 900px;
    margin: auto;
    display: flex;
    background-color: white;
    box-shadow:0px 5px 5px 5px rgb(219, 219, 218);
    border-radius: 15px;
    }
    
    .inputArea{
    flex: 1;
    padding: 60px 50px 0;
    }
    
    .deletHeading{
    color: #3b3b3b;
    font-size: 24px;
    letter-spacing: 2.5px;
    font-family: ProximaNova-Bold;
    margin-bottom: 25px; 
    }
    .loginButton{
    margin-bottom: 50px;
    }
    
    .imageArea{
    flex: 1;
    }
    .deleteMessageContainer{
    width: 900px;
    text-align: center;
    height: 500px;
    margin: auto;
    background-color: white;
    box-shadow:0px 5px 5px 5px rgb(219, 219, 218);
    border-radius: 15px;
    padding-top: 100px;
    }
    