

.mainDiv{
    display: flex;
    justify-content: center;
    /* position: relative; */
}

.cityName{
    display: flex;
    font-size: 28px;
    position: absolute;
    text-transform: capitalize;
    font-family: ProximaNova-Semibold;
}
    
.upperLayer{
    display: flex;
    font-size: 28px;
    /* top: 287px;
    left: 290px; */
    /* font-weight: bold; */
    position: absolute;
    text-transform: capitalize;
    font-family: ProximaNova-Semibold;
}

.dateContent{
    /* top: 285px;
    left: 670px; */
    /* font-weight: bold; */
    font-size: 22px;
    position: absolute; 
    font-family: ProximaNova-Semibold;
}

.slotNo{
    position: absolute;
    font-size: 22px;
    /* top: 320px;
    left: 540px; */                     
    /* font-weight: bold; */
    font-family: ProximaNova-Semibold;
}

.urgentAppt{
    font-size: 28px;
    position: absolute; 
    font-family: ProximaNova-Semibold;
}

.imageContainer{
    position: relative;
    width: 793px;
    height: 700px;
}

.imageContainer img{
    width: 100%;
}

.printButton{
    text-align: center;
}

/* .checkMark{
    width: 40px;
    height: 20px;
    border: 1px solid #000000;

} */

.checkMark{
    position: absolute;
    width: 20px;
    height: 20px;
    border: 2px solid #2688af;
    border-radius: 50%
}

.checkMark:after{
    content: "";
    background-color: transparent;

    /* position the checkbox */
    position: absolute;
    top: 1px;
    left: 5px;

    /* setting the checkbox */
        /* short arm */
    width: 6px;
    border-bottom: 2px solid #2688af;
        /* long arm */
    height: 11px;
    border-right: 2px solid #2688af;

    /* rotate the mirrored L to make it a checkbox */ 
    transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}


@page { size: auto;  margin: 0mm; }