.sideBorder {
  height: 200ppx;
  border: 1px solid black;
}

.apptListHeader {
  display: flex;
  justify-content: space-between;
  padding: 0 32px 0 25px;
  height: 79px;
  align-items: center;
}

/* >>>>>>>>>>>>> Styling left section of header <<<<<<<<<<<<<< */

.apptListLeft {
  width: 190px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.apptListLeft > img {
  width: 35px;
}

.paymentSummary {
  background-color: #f2f7fa;
  text-align: center;
  width: 140px;
  padding: 5px 0;
  /* border: 1px solid #f5c7a6; */
}

.ps-text {
  font-family: ProximaNova-Semibold;
  color: green;
  font-size: 14px;
}

.ps-amt {
}

/* Styling doctor status  */

.apptListAllDocs {
  padding: 0 10px;
}

/* Styling appointment for */

.apptFor {
  color: #2886af;
  font-family: ProximaNova-Semibold;
  font-size: 16px;
  line-height: 1.2;
}

.apptAllDoctors {
  color: #000;
  font-family: ProximaNova-Semibold;
  text-transform: capitalize;
  font-size: 18px;
  line-height: 1.4;
}

/* >>>>>>>>>>> Styling right section of header <<<<<<<<< */

/* Styling right container */
.apptListRight {
  display: flex;
  align-items: center;
  padding: 7px 15px;
  background-color: #2886af;
  border: 1px solid #2886af;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  /* height:39.2px; */
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
}

/* Styling button */

.apptListRight a {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  font-family: ProximaNova-Semibold;
  font-size: 14px;
}

.addApptLabel {
  color: #fff;
  font-family: ProximaNova-Semibold;
  font-size: 14px;
  margin: 0 5px;
  display: flex;
  align-items: center;
}
/* Styling button image */

.apptListRight a img {
  padding: 0 10px;
}

.sidebarContainer {
  padding-right: 5px;
  border: 2px solid #ececed;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
}

.modal-backdrop {
  /* opacity:1 !important; */
  /* background-color:#000; */
}

.scanPreviewHead {
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 1px 1px 5px #80808073;
}

.scanPreviewHead button {
  margin-left: 5px;
}

.previewModalBody {
  padding: 0;
}

.previewContent {
  display: flex;
  border-bottom: 1px solid #00000026;
}
.previewPrescriptionSection {
  position: relative;
  flex-basis: 70%;
  padding: 7px;
}

.previewPrescriptionSection img {
  border-radius: 5px;
  box-shadow: 1px 1px 5px #80808085;
}

.previewThumbnailSection {
  padding: 8px;
  text-align: center;
  flex-basis: 30%;
}

.previewThumbnailSection button {
  width: 100%;
  padding: 3px;
}
/* .scanIconImage{
    width: 30px;
    padding: 0 10px 0 0 !important;
} */

.previewScanThumbnail {
  height: 420px;
  overflow: auto;
}
.previewScanThumbnail img {
  box-sizing: border-box;
  margin: 5px 0;
  border-radius: 5px;
  padding: 3px;
}

.previewScanThumbnail img:hover {
  cursor: pointer;
  border: 1px solid red;
}

.deleteScan {
  position: absolute;
  font-size: 14px;
  right: 7px;
  padding: 2px 15px;
  margin: 7px;
  background-color: #000000b0;
  color: #fff;
  border-radius: 25px;
}

.deleteScan:hover {
  cursor: pointer;
}

.scannigSpinner {
  padding: 15px;
}

.scannigSpinner img {
  width: 32px;
  margin-right: 15px;
}

.rescanInProgress {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #0505055c;
}

.rescanInProgress img {
  width: 18px;
  margin-left: 10px;
}

.scanFor {
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 12px;
  line-height: 10px;
  text-transform: uppercase;
}

.scanPatientName {
  text-transform: uppercase;
  font-size: 14px;
  line-height: 14px;
  color: #dc3545;
}

.scanForHeading {
  width: 33%;
  padding: 0 7px;
}

.scannedrx {
  width: 9% !important;
  font-weight: bold;
}

.scannedrx:hover {
  cursor: pointer;
}

.noPrescription {
  font-size: 18px;
  font-weight: bold;
  color: grey;
  /* letter-spacing: 2px; */
  text-align: center;
  margin-top: 40px;
  /* margin: auto; */
  position: relative;
  flex-basis: 70%;
  padding: 7px;
  color: #8080804d;
}

.stopScanBtn {
  text-align: right;
}

.headButtonSection {
  display: flex;
  align-items: center;
}

.totalCollectionBtn {
  display: flex;
  align-items: center;
  padding: 4px 15px;
  margin-right: 20px;
  border: 1px solid #2886af;
  border-radius: 6px;
  text-transform: uppercase;
  font-size: 13px;
  color: #2886af;
  font-weight: bold;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.totalCollectionBtn > span {
  font-size: 18px;
  margin-right: 8px;
}

.totalCollectionBtn:hover {
  cursor: pointer;
  border: 1px solid #fff;
  background-color: #2886af;
  color: #fff;
  /* padding:7px 15px; */
}

.totalPaymentModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border-radius: 20px;
    border: 1px solid #000;
    overflow: hidden; */
}

.paymentModalBody {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
}

.paymentModalFooter {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #f2f2f2;
  height: 115px;
}

/*Modal Payment*/
.totalCollectionLabel,
.totalCollectionValue {
  color: #242424;
  font-family: ProximaNova-Semibold;
  font-size: 28px;
}

.totalCollectionLabel {
  /* padding: 10px 0; */
}

.paymentModeValue {
  color: #636363;
  font-size: 24px;
  padding: 0 5px;
}

.paymentModeLabel {
  font-family: ProximaNova-Semibold;
  color: #636363;
  font-size: 18px;
  display: flex;
  justify-content: center;
}
.paymentIconValue img {
  width: 30px;
}

.paymentIconValue {
  font-family: ProximaNova-Semibold;
  /* padding: 8px 0; */
  display: flex;
  align-items: center;
}

.modal-dialog {
  max-width: 600px;
}

.modal-content {
  border-radius: 20px;
  overflow: hidden; 
 
}

.closeButton {
  position: absolute;
  right: 25px;
  top: 15px;
  opacity: 0.7;
}

.closeButton img {
  width: 15px;
}
.closeButton:hover {
  cursor: pointer;
  opacity: 1;
}

.receivedBy {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}

.receivedBy > div {
  width: 50%;
  text-align: center;
}

.receivedBy > div > .receivedByAmt {
  font-weight: bold;
}

.quickApptBtn {
  margin-right: 15px;
  border: 1px solid #4e832f;
  color: #4e832f;
}
