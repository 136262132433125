.combinedPatientMainContainer {
  display: flex;
  /* width: 200vw;

    transform: translateX(-50%); */

  overflow-y: hidden;
  transition: all 0.5s linear;
  height: 100%;
}

.translateToRight {
  overflow: hidden;
  transform: translateX(0%);
  transition: all 0.5s linear;
}

.translateToLeft {
  overflow: hidden;
  transform: translateX(-50%);
  transition: all 0.5s linear;
}

/* >>>>>>>>>>>>>>>>>>> Styling new patient bar <<<<<<<<<<<<<<<<< */

.newPatientBar {
  display: none;
  align-items: center;
  /* justify-content: flex-end; */
  flex-direction: column;
  padding: 20px;
  position: relative;
  background: #f9f9f9;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  opacity: 1;
}

.navigateButton {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: #2886af;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.barContent1 {
  display: flex;
  color: #e1e1e1;
  font-size: 36px;
  font-family: ProximaNova-Bold;
  transform: rotate(270deg);
  transform-origin: 50% 65%;
  white-space: nowrap;
  position: absolute;
  margin: 170px 0 92px -84px;
}

.barContent2 {
  display: flex;
  color: #e1e1e1;
  font-size: 36px;
  font-family: ProximaNova-Bold;
  transform: rotate(90deg);
  transform-origin: 50% 65%;
  white-space: nowrap;
  position: absolute;
  margin: 165px 0 92px -73px;
}

/* >>>>>>>>>>>>> styling Existing patient <<<<<<<<<<<<<<< */

.spinnerIcon {
  width: 40px;
  height: 40px;
}

.spinnerIcon img {
  width: 100%;
}

/* Existing patient container */

.existingPatientContainer {
  position: relative;
  flex: 1;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  box-shadow: -4px 0 8px 0 rgba(0, 0, 0, 0.1);
  background: #f9f9f9;
  height: 100%;
  /* overflow-y: hidden; */
  /* height: 600px; */
  flex-wrap: nowrap;
  opacity: 1;
}

/* Existing patient title */

.existingPatientTitle {
  margin: 25px 0;
  color: #e1e1e1;
  font-size: 48px;
  text-transform: capitalize;
  font-family: ProximaNova-Bold;
}

.existingPatientTitle img {
  width: 320px;
  height: 40px;
  opacity: 0.25;
}

/* New patient form */

.existingPatientFormWrapper {
  /* align-self: center;
    padding: 20px 0; */
  margin-top: 55px;
}

.existingPatientForm {
  display: flex;
  margin: auto;
  /* align-items: center; */
  flex-direction: column;
  max-width: 450px;
}

/* Styling form label */

.existingPatientForm label {
  color: #3b3b3b;
  font-size: 18px;
  font-family: ProximaNova-Semibold;
  padding: 20px 0;
}

.existingPatientForm .inputGroup {
  display: flex;
  align-items: center;
  border: 1px solid #cecece;
  border-radius: 5px;
  padding: 0 5px;
}

.existingPatientForm .inputGroup .btn {
  /* flex: 1; */
  background-image: linear-gradient(271deg, #3ac1b0, #37b1e6);
  padding: 5px 10px;
  border-radius: 5px;
  text-decoration: none;
  color: #fff;
  font-family: ProximaNova-Regular;
  font-size: 16px;
  /* min-width: 150px; */
  min-width: 140px;
  text-transform: capitalize;
}

.existingPatientForm .inputGroup .dropdown-item {
  /* display: inline-block;
    border-top: 5px solid #fff;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    border-bottom: 5px solid transparent;
    margin-left: 15px; */
}

/* Styling form input */

.existingPatientForm .inputGroup input {
  border: none;
  border-radius: 5px;
  padding: 10px;
  flex: 2;
  order: 2;
  min-width: 250px;
}

.existingPatientForm .inputGroup::placeholder {
  color: #b2b2b2;
  font-family: ProximaNova-Semibold;
  font-size: 16px;
}

.existingPatientForm .inputGroup input:focus {
  outline: none;
  border-color: #2886af;
}

/* >>>>>>>>>>>>>. Styling new patient <<<<<<<<<<<<<< */

/* New patient container */

.newPatientContainer {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  box-shadow: 4px 0 8px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  overflow: hidden;
  z-index: 10;
}

.newPatBlock {
  padding: 20px 31px;
  margin-bottom: 30px;
}

/* New patient title */

.PatientTitle {
  /* background: linear-gradient(271deg, #3ac1b0, #37b1e6); */
  -webkit-background-clip: text;
  /* -webkit-text-fill-color: transparent; */
  /* font-size: 42px; */
  font-size: 35px;
  color: #37b1e6;
  text-transform: capitalize;
  font-family: ProximaNova-Bold;
  padding: 20px 0;
}

.newPatientTitle img {
  width: 320px;
  height: 40px;
}

/* New patient form */

.newPatientFormWrapper {
  /* align-self: center;
    width: 50%; */
  margin-top: 54px;
}

.newPatientForm {
  display: flex;
  flex-direction: column;
  max-width: 450px;
  margin: auto;
}

/* Styling form label */

.newPatientForm label {
  color: #3b3b3b;
  font-size: 16px;
  font-family: ProximaNova-Semibold;
  padding: 20px 15px;
}

/* Styling form input */

.newPatientForm input {
  border: 1px solid #b2b2b2;
  border-radius: 5px;
  padding: 10px;
  color: #3b3b3b;
  font-family: ProximaNova-Semibold;
  font-size: 16px;
  overflow: hidden;
  width: 100%;
}

.newPatientForm input::placeholder {
  color: #b2b2b2;
  font-family: ProximaNova-Semibold;
  font-size: 16px;
}

.newPatientForm input:focus {
  outline: none;
  border-color: #2886af;
}

/* Styling form confirmation message */

.confirmationMessage {
  display: flex;
  justify-content: flex-end;
  padding: 20px 0;
}

.confirmationMessage span {
  color: #939393;
  font-size: 16px;
  font-family: ProximaNova-Semibold;
  font-style: italic;
  padding: 0 10px;
}

.confirmationMessage p {
  font-family: ProximaNova-Semibold;
  color: #2886af;
  font-size: 16px;
}

.confirmationMessage p:hover {

  cursor: pointer;
}

/* Styling Add New Patient Link*/

.addNewPatientLink {
  text-align: center;
  color: #1396cf;
  font-size: 16px;
  font-family: ProximaNova-Semibold;
  text-decoration: underline;

}

.addNewPatientLink:hover {
  cursor: pointer;

}

.new {
  /* margin-bottom: 55px; */
  /* z-index: 10; */
}

/* .newPatient{
 
 margin-right: 8rem;
 text-align: center;
  color: #1396cf;
  font-size: 12px;
  font-family: ProximaNova-Semibold;
  text-decoration: underline;
} */


/* >>>>>>>>>>>>>>>>> Styling existing patient bar <<<<<<<<<<<<<<< */

.existPatientBar {
  display: none;
  align-items: center;
  /* justify-content: flex-end; */
  flex-direction: column;
  padding: 20px;
  position: relative;
  background: #f9f9f9;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
}

.navigateButton {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: #2886af;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.existingPatientScreen {
  overflow: hidden;
  display: flex;
  /* height: 650px; */
  box-shadow: -4px 0 8px 0 rgba(0, 0, 0, 0.1);
  width: 25%;
}

.newPatientScreen {
  overflow: hidden;
  display: flex;
  width: 25%;
  box-shadow: 4px 0 8px 0 rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.selectPatient {
  width: 200%;
  transition: all 0.4s linear;
  height: 100%;
}

.widthHalf {
  width: 50%;
  transition: all 0.5s linear;
}

.initialPos1 {
  transform: translateX(-50%);
  transition: all 0.5s linear;
}

.animatedFocusBlock {
  background: #fff;
  z-index: 11;
  transition: all 0.5s linear;
}

.zeroIndex {
  z-index: 0;
}

.recommendations {
  margin-top: 10px;
  display: flex;
  overflow: auto;
  /* height: 250px; */
  height: 200px;
  background: color #f7f7f7;
  /* width: 100%; */
  justify-content: flex-start;
  /* position: absolute; */
  /* width: 61%; */
  flex-wrap: wrap;
  padding: 15px;
  position: relative;
  left: 12%;
  bottom: 50px;
  /* overflow: auto; */

}

.recommendations::-webkit-scrollbar {
  width: 7px;
}

.recommendations::-webkit-scrollbar-button {
  background: #fff;
  display: none;
}

.recommendations::-webkit-scrollbar-track-piece {
  background: transparent;
}

.recommendations::-webkit-scrollbar-thumb {
  background-color: #e2e2e2;
}

.btnDisabled {
  opacity: 0.5;
}

.btnDisabled:hover {
  cursor: unset !important;
  background-color: initial !important;
}