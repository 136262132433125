#root{
    height:100%
}
.App{
    height:100%;
}

.App>div{
    height:100%;
}

.container{
    height:100%
}

.patientProfile{
    display:flex;
    height:100%;
}

.pprLeft{
    width:20%;
    height:100%;
    padding:80px 0 25px 0;
}

.pprLeft>div{
    background-color: #626670;
    height:100%;
    overflow:auto;
}

.pprRight{
    width:80%;
    padding:6px 10px 25px 10px;
    padding-top:80px;
}

.pprInvoiceContainer{
    height:100%;
    overflow:auto;
    padding-top:15px;
    background-color: #fff;
}

.profileHead {
    display:flex;
    justify-content: center;
    flex-direction: column;
    height:150px;
    text-align: center;
    background-color:#4090c3;
}

.pprTabs{
    padding:20px 15px;
    background-color: #fff;
    border-bottom:2px solid #77777763;
    display:flex;
    justify-content: space-around;
}

.pprTabs > div{
    width:50%;
    text-align: center;
    text-transform: uppercase;
    color:#777777;
}

.pprTabs > div:hover{
    cursor: pointer;
}









/* .patientProfile{
    display:flex;
    flex:1;
    overflow:hidden;
    position:relative;
}

.ppLeft{
    display: flex;
    flex-direction:column;
    overflow: hidden;
    position:fixed;
}

.ppRight{
    position:relative;
    left:22%;
    width:78%;
    display:flex;
    flex-direction:column;
    overflow:hidden;
    padding:15px;
}

.patientProfileSideBar{
    margin-top:15px;
    max-height:85vh;
    display:flex;
    flex-direction:column;
    overflow: auto;
} */


/* 
.patientProfileCard{
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-image: linear-gradient(to bottom, #3694bf, #548acc);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.patientProfileContainer{
    width:16.25rem;
    display: flex;
    text-align: center;
    flex-direction: column;
    color: #fff;
}

.patientPhoto{
    display: flex;
    justify-content: center;
    padding: 14px 0;
}

.patientDetails{
    padding: 0 0 10px 0;
}
.basicDetails{
    /* display: flex; */
    /* font-family: ProximaNova-Semibold;
    font-size: 16px;
    justify-content: center;
    margin-bottom: 2px;
} */

/* .basicDetails .ageGender{ */
    /* margin: 0 5px; */
/* } */

/* .mobileNumber{
    font-family: ProximaNova-Regular;
    font-size: 14px;
} */

/* .phoneCode{
   
} */


/* .patientOtherDetails{
    height: 635px;
    background-color: #626670;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
} */

/* .patientAccountDetails{
    padding: 14px 15px;
    font-size: 14px;
    color: #fff;
} */

/* .patientVitalDetails{
    padding: 0 15px;
    font-size: 14px;
    color: #fff; */
/* } */

/* Common styling for account and vital details */

/* .detailsMainHeading{
    padding: 9px 7px;
    border-bottom: 1px solid #fff;
    font-family: ProximaNova-Semibold;
}

.detailsRow{
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
}

.detailsHeading{
    font-family: ProximaNova-Regular;
    color: #c4c4c4;
    flex: 1;
}

.detailsValue{
    flex: 1;
    font-family: ProximaNova-Semibold;
} */

/* Styling account details */


/* .accountDetails{
    padding:0 9px;
} */

/* Styling vital details */

/* .vitalDetails{
    padding: 5px 9px;
} */


/*Right*/
/* .invoicesTabs{
    display: flex;
    border-bottom: 1px solid #7070703d;
    color: #777777;
    font-family: ProximaNova-Regular;
    font-size: 18px;
    margin-bottom:35px;
}

.invoiceTab{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 0;
    margin: 0 11px;
    cursor: pointer;
}

.invoiceTab.active{
    border-bottom: 3px solid #2886af;
} */

/*+++++++++++++++*/
.lightFontColor{
    color: #939393;
}

/* Styling outer appointment card */
.apptCard{
    display: flex;
    height: 142px;
}

/* Styling timings part */

.apptTimings{
    display: flex;
    align-items: center;
    padding: 0 15px;
    border-right: 1px solid #D0D0D0;
    position: relative;
}

.apptTime{
    font-size: 14px;
    font-family: ProximaNova-Regular;
    line-height: 1.2;
}

.apptDate{
    color: #000000;
    font-size: 14px;
    font-family: ProximaNova-Semibold;
    line-height: 1.2;
}

.apptYear{
    font-size: 14px;
    font-family: ProximaNova-Regular;
}
.circle{
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 3px solid #2886af;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background: #fff;
    position: absolute;
    right: -9px;
    
}



.line{
    width: 14px;
    height: 1px;
    background: #2886af;
    position: absolute;
    right: -22px;
}

/* Stying details card */

.apptDetailCard{
    display: flex;
    align-items: center;
    padding: 0 0 0 20px;
    width: 830px;
    transition: all 0.5s ease-in; 
}

.apptDetails{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border-radius: 23px;
    color: #fff;
    background-image: linear-gradient(to bottom, #3694bf, #548acc);
}

.apptCardHeading{
    font-family: ProximaNova-Semibold;
    font-size:16px;
    color: #90c6ed;
}

.serviceHeading{
    display: flex;
    align-items: center;
    margin-bottom: 4px;
}

.servicesCount{
    color: #fff;
    font-size: 14px;
    font-family: ProximaNova-Semibold;
    text-align: center;
    height: 23px;
    border: 1px solid #fff;
    border-radius: 14px;
    padding: 0 9px;
    margin: 0 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.servicesNames{
    font-size: 15px;
    font-family: ProximaNova-Bold;
    line-height: 1.5;
}

.paymentHeading{
    color: #90c6ed;
    font-size: 16px;
    font-family: ProximaNova-Semibold;
}

.payment{
    border-radius: 14px;
    border: 1px solid #fff; 
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 11px;
    margin: 10px 0;
    font-family: ProximaNova-Bold;
    font-size: 14px;
    transition: all 0.5s ease-in; 
    cursor: pointer;
}

.payment:hover{
    color: #2886af;
    background: #fff;
    transform: scale(1.1);
}

.pp-status{
    font-size: 15px;
    font-family: ProximaNova-Bold;
    margin: 9px 0;
}

.apptButtons{
    display: flex;
    align-items: center;
    font-family: ProximaNova-Semibold;
}

.apptButton{
    padding: 5px 10px;
    border-radius: 24px;
    border: 1px solid #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.5s ease-in; 
    font-size: 14px;
}

.apptButton:hover{
    color: #2886af;
    background: #fff;
    transform: scale(1.1);
}

.cancelButton{
    padding: 5px 30px; 
    margin: 0 17px;
}

/*INtegrated Code*/

.red{
    color: #ff0000;
}

.green{
    color: #03951f
}

.invoiceDetailsContainer{
    display: flex;
    border-bottom: 2px solid #7070704d;

}

.invoiceTable{
    width: 65%;
    padding: 0 16px 10px 16px;
}

table{
    border-collapse:collapse;
    width: 100%;
}

.tableHeading{
    border-bottom: 1px solid #7070703d !important;
    color: #777777;
    font-family: ProximaNova-Semibold;
    font-size: 14px;
}

.tableHeading > th{
    padding: 18px 10px;
    text-align: left;
}

.invoicePaymentDetails{
    width: 35%;
    padding: 28px 18px;
    font-family: ProximaNova-Semibold;
    font-size: 16px;
}

/* .invoicePaymentDetails div{
    display: flex;
    align-items: center;
} */

.billedPayment, .discount, .total, .paid, .balance{
    display: flex;
    align-items: center;
    padding: 10px 20px;
}

.discount{
    border-bottom: 2px solid #77777745;
}

.total{
    border-bottom: 1px solid #777777ad;
}


.invoicePaymentDetails .label{
    color: #767676;
    flex: 1;
    display: flex;
    justify-content: flex-end;
}

/* .invoicePaymentDetails .label.discountLabel{
    justify-content: flex-end;
} */

.billedAmount, .discountAmount, .totalAmount{
    color: #333333;
}

.balance{
    padding: 0 20px 10px 10px;
}

.amount{
    display: flex;
    justify-content: flex-end;
}

.paidAmount{
    color: #000000
}

.costHeading, .totalHeading, .unitHeading{
    width: 20%;
}

tbody>tr>td{
    padding: 12px 10px;
}

tbody > tr > td > h3{
    font-size: 16px;
    font-family: ProximaNova-Semibold;  
    margin: 0 0 5px 0;
}

.subHeading{
    font-family: ProximaNova-Regular;
    font-style: italic;
    color: #5c5c5c;
    font-size: 14px;
}

.totalAmt{
    font-size: 16px;
    color: #1d1d1d;
    font-family: ProximaNova-Semibold;
}

.billedPayment > div{
 flex: 1;
}

.discount > div{
 flex: 1;
 display: flex;
}

.billedAmount{
    display: flex;
    justify-content: flex-end;
}

.total > div{
 flex: 1;
}

.paid > div{
 flex: 1;
}

.balance > div{
 flex: 1;    
 display: flex;
}










.invoicesTabs {
    display: flex;
    border-bottom: 1px solid #7070703d;
    color: #777777;
    font-family: ProximaNova-Regular;
    font-size: 18px;
    height: 50px;
    flex: 1 1;
    position: fixed;
    width: 71%;
    background: #fff;
    z-index: 100;
}


.redColor{
    color: #ff0000
}

.invoicesHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f2f7fa;
    height: 65px;
    padding: 0 22px;
    /* position: fixed; */
    width: 100%;
    top: 80px;
    z-index: 100;
}

.invoicesLeft{
    color: #030303;
    font-size: 24px;
    font-family: ProximaNova-Semibold;
}

.invoicesRight{
    display: flex;
}

.collectRefundPayment{
    border: 1px solid #2886af;
    border-radius: 60px;
    padding: 5px 17px;
    background: #ffffff;
    margin-right: 17px;
    font-size: 14px;
    cursor: pointer;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.collectRefundLabel{
    color: #505050;
    font-family: ProximaNova-Semibold;
    margin-right: 5px;
}

.collectRefundAmount{
    font-family: ProximaNova-Semibold;
}

.addInvoiceBtn{
    background: #2886af;
    color: #ffffff;
    font-size: 14px;
    font-family: ProximaNova-Semibold;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: 1px solid #2886af;
    border-radius: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    text-transform: uppercase;
    cursor: pointer;
}

.addInvoiceBtn{
    text-decoration: none;
    color: #fff;
}

.addInvoiceBtn:hover{
    text-decoration: none;
    color: #fff;
}


::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #4090c3; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #4090c3; 
  }

.patientDashboardContainer{
    display: flex;
    padding-top: 30px;
}


/* @font-face {
    font-family: ProximaNova-Regular;
    src: url('../../../assets/fonts/ProximaNova-Regular.otf');
}

@font-face {
    font-family: ProximaNova-Semibold;
    src: url('../../../assets/fonts/ProximaNova-Semibold.otf');
}

@font-face {
    font-family: ProximaNova-Bold;
    src: url('../../../assets/fonts/ProximaNova-Bold.ttf');
}

@font-face {
    font-family: ProximaNova-Light;
    src: url('../../../assets/fonts/ProximaNova-Light.otf');
}*/

::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #4090c3; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #4090c3; 
  }

.patientDashboardContainer{
    display: flex;
    padding-top: 30px;
}

/*
.patientDashboardRight{
    position: relative;
    background: #fff;
    margin-left: 25%;
    width: 80%;
    padding: 0 30px 0 0;
    overflow-y: scroll;
    height: 600px;
}

.invoicesTabs{
    display: flex;
    border-bottom: 1px solid #7070703d;
    color: #777777;
    font-family: ProximaNova-Regular;
    font-size: 18px;
    height: 50px;
    flex: 1;
    position: fixed;
    width: 71%;
    background: #fff;
    z-index: 100;
}

.invoiceTab{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 0;
    margin: 0 11px;
    cursor: pointer;
}

.invoiceTab.active{
    border-bottom: 3px solid #2886af;
    color: #2886af;
    font-family: ProximaNova-Bold;
} */













.invoiceHeaderContainer{
    height: 75px;
    background: #4090c3;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
    padding: 0 25px;
}

.invoiceHeaderLeft{
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #ffffff;
}

.invoiceNumber{
    text-transform: uppercase;
    font-family: ProximaNova-Semibold;
    font-size: 16px;
    padding: 7px 0;
}

.invoiceDateTime{
    display: flex;
    font-size: 12px;
    font-family: ProximaNova-Regular;
}

.invoiceTime{
    padding: 0 13px;
}

.invoiceHeaderRight{
    display: flex;
    align-items: center;
}

.invoiceCollectRefund{
    display: flex;
    align-items: center;
    border: 1px solid #ffffff;
    border-radius: 60px;
    padding: 8px 17px;
    color: #ffffff;
    font-family: ProximaNova-Semibold;
    font-size: 14px;
    cursor: pointer;
}

.invoiceCollectRefundPayment{
    padding: 0 5px;
    display: flex;
    align-items: center;
}

.invoiceCollectRefundAmount{
    padding: 0 3px;
}

.editInvoiceIcon{
    padding: 0 40px;
}

/* .printInvoiceIcon{
    padding: 0 40px 0 0;
} */

.editIconImage, .printerIconImage{
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.pprLeft * h4{
    color:#fff;
    font-size: 14px;
    padding:15px;
    border-bottom:1px solid #ffffff70;
}

.pprLeft * table > tr >td{
    padding:0 15px;
    font-size: 14px;
    line-height:25px;
}

.pprLeft * table > tr >td.key {
    color : #c4c4c4;
}

.pprLeft * table > tr >td.value {
    color : #fff;
}

.namePat{
    color:#fff;
    font-weight: bold;
    font-size: 14px;
}

.numPat{
    color:#fff;
    font-size: 14px;
    opacity: 0.8;
}









