
/* Login modal */

.loginModal{
    overflow: hidden;
    width: 900px;
    display: flex;
    background: #ffffff;
    box-shadow: 0 27px 35px 0 #c9c9c9;
    border-radius: 10px;
}

/* Styling Login form */

.loginForm{
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 60px 50px 0 50px;
    width: 50%
}

/* styling login title */

.loginTitle{
    color: #3b3b3b;
    font-size: 24px;
    letter-spacing: 2.5px;
    font-family: ProximaNova-Bold;
    margin-bottom: 25px;
}

/* styling form */

.loginForm form{
    display: flex;
    flex-direction: column;
    padding: 0 0 50px 0;
}

/* Styling form label */

/* .loginForm form label{
    color: #cecece;
    font-size: 14px;
    font-family: ProximaNova-Semibold;
} */

/* .loginForm form input{
    border: 0;
    outline: 0;
    padding: 0.2rem 0;
    border-bottom: 1.5px solid #c9c9c99e;
    box-shadow: none;
    color: #000000;
    font-size: 18px;
    font-family: ProximaNova-Semibold;
} */

.input-field-login{
    position: relative;
    height: 35px;
    margin-top: 40px;
}

/* Common styling for all input fields */

.input-field-login label {
    position: absolute;
    z-index:4;
    top: 0;
    left: 0;
    /* width: 100%; */
    color: #cecece;
    transition: 0.2s all;
    cursor: text;
    font-family: ProximaNova-Semibold;
    font-size: 14px;
}

.input-field-login label:focus{
    outline: 0;
}

.input-field-login input{
    position: relative;
    z-index:5;
    background-color:transparent; 
    width: 100%;
    border: 0;
    line-height: 1;
    outline: 0;
    padding:5px 0;
    border-bottom: 1.5px solid #cececea8;
    box-shadow: none;
    color: #000000;
    font-size: 18px;
    font-family: ProximaNova-Semibold;
}

.input-field-login input:focus,
.input-field-login input:valid {
    border-color: #cececea8;
}

.input-field-login input:focus ~ label,
.input-field-login input:valid ~ label
{
    top: -20px;
    color: #cecece;
}

.forgotPasswordLink a{
    color: #2886af;
    font-family: ProximaNova-Semibold;
    font-size: 12px;
    /* text-transform: capitalize; */
}

.loginButton{
    background: #2886af;
    text-transform: uppercase;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    border-radius: 60px;
    border: none;
    font-family: ProximaNova-Semibold;
    font-size: 18x;
    margin: 58px 0 0 0;
    cursor: pointer;
}

.loginButton:focus{
    outline: none;
}
/* Styling Login Image */

/* .loginImage{
    flex: 1;
    width: 50%;
    height: 530px;
    overflow: hidden;
} */

/* .loginImage img{
    max-width: 100%;
    height: auto;
    object-fit: cover;
}     */

.loginButton:disabled{
    opacity:0.4;
    cursor:default;
}

.loginImage{
    /* flex: 1; */
    width: 50%;
    height: 491px;
    background: url('../../assets/images/login_image.jpg') no-repeat center;
    background-size: cover;
}

.invalidMsg{
    color: crimson;
    /* font-family: ProximaNova-Semibold; */
    padding: 5px 10px;
    text-align: center;
    font-size: 14px;
    background: #894a1914;
}