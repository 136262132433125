.menu-list .active{
    color: green;
  }
  

  /* ******** sidebar Container ********* */
  
  .sidebar{
    max-height:360px;
    overflow:auto;
  }

.sidebar::-webkit-scrollbar{
    width:4px;
}
.sidebar::-webkit-scrollbar-button {
    background: #fff;
}

.sidebar::-webk7t-scrollbar-track-piece {
    background: #fff;
}

.sidebar::-webkit-scrollbar-thumb {
    background-color : #ebebeb;
}



  
  /* ********* sidebar title ********* */
  
  .sidebar-title p{
    padding: 15px 20px;
    color: #09163c;
    font-family: ProximaNova-Semibold;
    font-size: 15px;
    text-align: left;
    margin: 0;
  }
  
  /****** Sidebar dropdown *********** */
  .sidebar-dropdown{
    display:flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #ececed;
    border-bottom: 1px solid #ececed;
    background-color: #ffffff;
    padding:15px 10px;
    border-left:8px solid #f5d5b8; 
  }
  .sidebar-dropdown:hover{
      cursor:pointer;
  }

  .sidebar-dropdown .doc-profile{
      display:flex;
      align-items: center;
  }


  .notification-icon{
       /* width: 20px; */
    /* height: 20px; */
    /* line-height: 20px; */
    padding: 0px 6px;
    text-align: center;
    font-size: 12px;
    border-radius: 25px;
    color: #ffffff;
    background-color: #187a9e;
    font-family: ProximaNova-Bold;
  }
  
  .dropdownMenu{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding:15px;
  }
  
  .dropdownMenu .text{
    text-align: left;
    font-size: 14px;
    color: #838383;
    text-decoration: none;
    flex-basis:70%;;
    /* padding-left: 50px;
    padding-right: 50px; */
  }
  
  .dropdownMenu .counts{
    text-align: right;
    font-size: 22px;
    /* padding: 15px 10px; */
    line-height: 1;
    color: #2886af;
    font-family: ProximaNova-Bold;
  }
  
  .dropdownMenu .red{
    color: #ff5858;
  }
  
  .dropdownMenu .right-arrow{
    padding: 21px 13px;
    width: 8px;
    height: 13px;
  }
  .dropdownMenu:hover,
  .activeItem{
    background-color:#f2f7fa;
    color:#838383;
    cursor:pointer;
  }
  
  /* ******* profile *******/
  
  .profile-image{
    border-radius:50%;
    background-color: #187a9e;
    overflow: hidden;
    width: 37px;
    height: 37px;
  }

  .profile-image{
    display: flex;
    justify-content:center;
    align-items:center;
    font-size: 14px;
    text-transform:capitalize;
    font-family:ProximaNova-Bold;
    color:#fff
  }

  .profile-image img{
    width: 37px;
    height: 37px;
  }
  
  .profile-content{
    /* float: left;
    padding-left: 12px;       */
    margin-left:20px;
  }
  
  .profile-content h3{
    font-size: 14px;
    color: #187a9e;
    text-transform: capitalize;
    /* padding-bottom: 2px; */
  }
  
  .profile-content p{
    color: #838383;
    text-align: left;
    font-size:14px;
    text-transform: capitalize;
    /* padding-top: 2px; */
  }

  .accordion__heading:focus,
  .accordion__button:focus{
    outline:none; 
  }

  .sidebarContainer{
    padding-bottom:15px;
  }
  