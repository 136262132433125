.appointments-container{
    display: flex;
    align-items: center;
    padding: 15px 25px;
}

.appointments-status{
    color: #4e4e4e;
    font-size: 16px;
    flex-basis: 30%;
    font-family: ProximaNova-Semibold;
}

.appointments-search{
    flex-basis: 40%;
}

.appointments-search input[type="text"]{
    width:100%;
    box-shadow: none;
    padding: 5px 0;
    outline: none;
    border:none;
    border-radius: 30px;
    text-indent: 3em;
    background: #efefef url(../../assets/images/icons/search-icon.svg) no-repeat 5%;
}

.appointments-search input[type="text"]:focus{
    outline: none;
}

.appointments-search input[type="text"]::placeholder{
    color: #d5d5d5;
    padding: 10px;
    font-family: ProximaNova-Regular;
    font-size: 16px;
}

.add-appointment-button{
    float:right;
    font-size: 14px;
    line-height: 1;
    text-align: center;
    padding: 10px 20px;
    border-radius: 60px;
    border: 1px solid #2886af;
    font-family: ProximaNova-Semibold;

}

.add-appointment-button:hover{
    text-decoration: none
}

.add-appointment-button span{
    text-align: center;
    text-transform: uppercase;
    color: #2886af;
    text-decoration: none;
}


/* Styling appt list container */

.apptListContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 18px;
    padding: 0 18px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
    border: solid 1px rgba(248, 212, 185, 0.5);
    border-left: 10px solid #f8d4b9;
    border-radius: 7px;
    height:50px;
    margin-bottom:15px;
    
}

.apptListContainer>div{
    width:30%;
}

/* Styling patient name */

.apptListName{
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 35% !important;
    font-family: ProximaNova-Semibold;
    color: #000;
    word-break: break-word;
    /* white-space: pre-wrap; */
    font-size: 16px;
    padding-right: 15px;
    text-transform: capitalize;
    overflow: hidden;
}

.actionButtons{
    position: relative;
    text-align: right;
    display: flex;
}

/* Styling patient gender */

.apptListGender{
    color: #636363;
    font-family: ProximaNova-Regular;
    font-size: 14px;
    text-transform: capitalize;
}

/* Styling patient time */

.apptListTime{
    color: #636363;
    font-family: ProximaNova-Regular;
    font-size: 14px;
}

/* Styling patient contact number */

.apptListContact,
.apptUpdatedtm{
    color: #636363;
    font-family: ProximaNova-Regular;
    font-size: 14px;
}

/*Styling action buttons */

.actionButtons img{
    height:20px;
    margin-right:15px;
    /* padding: 0 10px; */
}

.actionButtons img:hover{
    cursor:pointer;
}



/* Styling options divs */

.apptListOptions{
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border: 1px solid #ececed;
    background-color: #fff;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
    color: #3c3c3c;
    font-family: ProximaNova-Semibold;
    border-radius: 10px;
    width: 20%;
}

/*Styling children of options div */

.apptListOptions .editDetails, .apptListOptions .addRecords{
    box-sizing: border-box;
    width: 100%;
    padding: 20px;
}

.apptListOptions .addRecords{
    background-color: #f2f7fa;
}



.apptHour{
    display: flex;
}

.apptHour .hour{
    width:7.4%;
    font-family: ProximaNova-SemiBold;
    font-size: 14px;
    text-align: right;
    font-size: 13px;
    padding-right:7px;
    color:#2886af;


}

.apptHour .apptList{
    width:92.6%;
    padding-top:20px;
    border-top:3px solid rgba(0, 0, 0, 0.06);
    margin-top:7px;
    box-sizing: border-box;
    position:relative;
   
}

.dayAppointments{
    padding: 10px 18px;
    max-height:800px;
    overflow: auto;
   
}

.apptColHeading{
    background-color:#f9f9f9;
    display:flex;
    min-height:28px;
    align-items: center;
    padding:0 25px;
    font-size:13px;
    color:#939393;
}

/* .apptColHeading .timeline{
} */

.patientApptTitle{
    display:flex;
    width:100%;
}
.patientApptTitle .nameTitle{
    width:20%;
    padding-left:7.8%;
}

.patientApptTitle .genderTitle{
    width:20%;
    padding-left:10.9%;
}

.patientApptTitle .apptTimeTitle{
    width:20%;
    padding-left:7.3%

}

.patientApptTitle .mobileTitle{
    width:20%;
    padding-left:3.6%;
}

.dayAppointments::-webkit-scrollbar{
    width:8px;
}
.dayAppointments::-webkit-scrollbar-button {
    background: #fff;
}

.dayAppointments::-webkit-scrollbar-track-piece {
    background: #fff;
}

.dayAppointments::-webkit-scrollbar-thumb {
    background-color : #ebebeb;
}

/*Time Line*/
.timeline{
    position: absolute;
    top: -2px;
    width: 100%;
}

.timelineMarker{
    width: 15px;
    height: 15px;
    background:#2886af;
    border-radius: 50%;
    position: absolute;
    top: -7px;
    left: -6px;
}

.hrline{
    background-color:#2886af;
    height:1px;
}


/*Appt Options*/

apptListOptions{
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border: 1px solid #ececed;
    background-color: #fff;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
    color: #3c3c3c;
    font-family: ProximaNova-Semibold;
    border-radius: 10px;
    width: 100%;
}

.apptListOptions a{
    text-decoration: none;
    color: #000;
}
/*Styling children of options div */

.apptListOptions .editDetails, .apptListOptions .addRecords{
    box-sizing: border-box;
    width: 100%;
    padding: 20px;
}

.apptListOptions .addRecords{
    background-color: #f2f7fa;
}

.dayViewLoader{
    text-align:center;
    margin-top:35px;
}

.dayViewLoader img{
    width:40px
}

.noData{
    text-align: center;
    font-size: 16px;
    margin:35px auto;
    font-family: ProximaNova-Semibold;
}

.updatedtmTitle,
.apptUpdatedtm{
  padding-left:0;  
}

.editpay{
    padding-left:3.5%;  
}


/*====*/
.titleline{
    padding-left:1%;
    width:10%;

}

.nameTitle{
    width:18%;
}

.genderTitle{
    width:13%;
}

.apptTimeTitle{
    width:13%;
}

.mobileTitle{
    width:13%;
}

.emptyTitle{
    width:5%;
}

.emptycol{
    width:12%;
}

.headButtonSection{
    display: flex;
    align-items: center
}

.recentApptBox{
    display: flex;
    flex-direction: column;
    padding: 0 5px;
    border-radius: 5px;
    border: 1px solid #2688af42;
    /* box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.10); */
}

.recentApptHeading{
    /* width:10.4%; */
    font-family: ProximaNova-Semibold;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    padding: 8px 10px;
    color:#2886af;
    cursor: pointer;
}

.recentAppts{
    /* padding: 0 0 10px 0; */
    border-radius: 7px;
    /* padding: 0 10px; */
    margin-bottom: 10px;
    border: 1px solid #2886af2b;
}

.recentApptsContainer{
    display: flex;
}

.spaceConsumer{
    width: 7.4%;
}

.recentApptsContainer > .apptListContainer, .recentApptsList{
    width: 92.6%;
}

.card{
    padding: 0;   
    border: none;
}

.card-body{
    padding: 0;
}


