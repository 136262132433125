.patientCardContainer {
  margin: 8px;
  width: 194px;
  border: 1px solid #cecece;
  background: #ffffff;
  display: flex;
  border-radius: 7px;
  padding: 10px;
  height: 60px;
}

.patientCardContainer:hover {
  cursor: pointer;
  background: #2e8aaf0f;
  overflow: hidden;
}

.patientImage {
  width: 20%;
  height: 25px;
  border-radius: 50%;
  background: #2e8aaf0f;
}

.patientImage img {
  width: 100%;
  /* height:25px; */
}

.patientInformation {
  padding: 0 10px;
  width: 80%;
}

.patientName {
  font-size: 14px;
  font-family: ProximaNova-Semibold;
  color: #2c2c2c;
  line-height: 1.6;
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.patientNumber {
  color: #7e7e7e;
  font-size: 12px;
  font-family: ProximaNova-Semibold;
  font-style: italic;
}
