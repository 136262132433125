.mainContainer {
  padding: 20px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  box-shadow: 0 3px 6px 0 #0000001a;
  border-radius: 0 0 15px 15px;
}

.editDetailsHeading {
  color: #3b3b3b;
  font-size: 18px;
  font-family: ProximaNova-Semibold;
  text-transform: capitalize;
  padding: 35px 30px 20px 30px;
}

/* >>>>>>>>>>>  Styling Basic details <<<<<<<  */

.requiredField {
  color: red;
  font-size: 18px;
}

.basicDetails {
  width: 100%;
  padding: 20px 0;
  overflow: auto;
 height: 60vh;
}

.basicDetails .basicDetailsForm {
  padding: 0 50px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.basicDetails .input-field {
  position: relative;
  width: 50%;
  height: 65px;
  margin-top: 35px;
}

.basicDetails .input-field.city-field {
  margin-top: 60px;
 
}

.apptTypeButtons {
  display: flex;
  padding: 5px 0;
}

.apptTypeButton {
  display: flex;
  justify-content: center;
  width: 95px;
  border: 1px solid #2886af;
  border-radius: 60px;
  font-size: 12px;
  font-family: ProximaNova-Semibold;
  color: #2886af;
  padding: 3px 10px;
  cursor: pointer;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.apptTypeButton.active {
  background-color: #2886af;
  color: #fff;
}

.expressButton {
  margin: 0 25px;
}
/* Common styling for all input fields */

.input-field label {
  position: absolute;
  top: 0;
  left: 0px;
  width: 100%;
  color: #939393;
  transition: 0.2s all;
  cursor: text;
  font-family: ProximaNova-Regular;
  font-size: 16px;
}

.input-field label:focus {
  outline: 0;
}

.input-field input {
  position: relative;
  z-index: 6;
  width: 80%;
  border: 0;
  background: none;
  outline: 0;
  padding: 5px 0;
  border-bottom: 1.5px solid #cececea8;
  box-shadow: none;
  color: #000000;
  text-transform: capitalize;
  font-size: 16px;
  line-height: 1;
  font-family: ProximaNova-Semibold;
}

.input-field input:invalid {
  outline: 0;
}

.input-field input:focus,
.input-field input:valid {
  border-color: #cececea8;
}

.input-field input:focus ~ label,
.input-field input:valid ~ label {
  top: -24px;
  color: #939393;
}

.animateDropDownLabel {
  top: -12px !important;
}

.input-field .dropdownLabel {
  cursor: pointer;
  top: 12px;
  font-size: 16px;
  font-family: ProximaNova-Semibold;
  text-transform: capitalize;
}

/* Styling vital details */

.mainContainer {
  min-height: 100vh; 
 
}

.mainContainer .vitalDetails {
  width: 100%;
  padding: 10px 0;
  height: 100%;
  /* overflow: auto; */
}

.vitalDetails .vitalDetailsForm {
  padding: 0 50px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  height: auto;
}

.vitalDetails .input-field {
  position: relative;
  width: 50%;
  height: 35px;
  margin-top: 45px;
}

/* >>>>>>>>>>> Styling dropdown <<<<<<<<<<<< */

.dropDownMenu {
  position: relative;
  width: 85%;
  height: 40px;
  border-bottom: 1.5px solid #cececea8;
  font-family: ProximaNova-Semibold;
  color: #000;
  cursor: pointer;
  padding: 0px 13px;
  display: flex;
  align-items: center;
}

.fullWidthDropDownMenu {
  width: 100%;
}

/* Custom dropdown arrow */

.dropDownMenu::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  top: 50%;
  right: 20px;
  border-top: 6px solid #2886af;
  border-right: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid transparent;
  z-index: 9;
}

/* showing dropdown when clicked on div */

ul.showDropDown {
  display: block;
}

.dropDown {
  position: absolute;
  width: 100%;
  top: -4px;
  left: 0;
  padding-left: 0;
  background: #fff;
  z-index: 150;
  box-shadow: 1px 1px 10px #8080806b;
  display: none;
}

.dropDown li {
  list-style: none;
  font-family: ProximaNova-Semibold;
  font-size: 18px;
  border: 1px solid #cececea8;
  padding: 10px;
  color: #000000;
}

/* changing background on hover */
li:hover {
  background: #2886af;
  transition: all 0.5s ease-in;
}

li:hover span {
  color: #fff !important;
}

/* li:hover .listContent{
    position: relative;
    color: #fff;
    z-index: 150;
}

li:hover .listBackground{
    width: 300px;
    position: absolute;
    background: #2886af;
    transition: all 0.5s ease-in;
    z-index: 100;
    height: 40px;
} */

.listBackground {
  position: absolute;
  background: #2886af;
  transition: all 0.5s ease-in;
}

/* @keyframes moveup
{
    0%{ bottom: -25px; opacity: 0;}
    100%{ bottom: 0; opacity: 1;}
}

@keyframes movedown
{
    0%{ top: -25px; opacity: 0;}
    100%{ top: 0; opacity: 1;}
}

@keyframes slidein
{
    from{ transform: translateX(100%); opacity: 0;}
    to{ transform: translateX(0); opacity: 1;}
} */

@-webkit-keyframes slide {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes slide {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

.dropDownContainer {
  display: flex;
  align-items: flex-end;
  width: 80%;
}

.dropDownSpan {
  display: inline-block;
  text-align: right;
  padding-left: 20px;
}

.dropDownSpan a {
  text-decoration: none;
  color: #2886af;
  font-size: 13px;
  font-family: ProximaNova-Semibold;
}

.dropMenu {
  width: 80%;
  border-bottom: 1.5px solid #cececea8;
}

.dropdownLabel::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  margin-top: 5px;
  top: 7px;
  right: 20%;
  border-top: 6px solid #2886af;
  border-right: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid transparent;
  z-index: 9;
}

.dropdown-menu {
  width: 80%;
  max-height: 200px;
  overflow: auto;
  box-shadow: 1px 3px 30px #2e8aaf36;
  border-radius: 0 0 7px 7px;
  border: none;
}

.age-field .dropdown,
.height-field .dropdown {
  width: 65%;
  display: inline-block;
}

.age-field .dropdown-menu,
.height-field .dropdown-menu {
  width: 100%;
}

.age-field .dropMenu,
.height-field .dropMenu {
  width: 100%;
}

.age-field .dropdownLabel::after,
.height-field .dropdownLabel::after {
  right: 0;
}

.dobMenu .dropdown-menu {
}

.dobMenu {
  max-height: unset !important;
  transform: none !important;
  box-shadow: none;
  top: 24px !important;
  overflow: unset;
}

.dobMenu .dobCalander {
  top: unset;
}

.dobMenu .dobCalander .react-calendar__tile {
  padding: 0.5em 0;
}

.dobMenu .dobCalander .react-calendar__navigation {
  height: unset;
}

.dropdown-menu ul {
  margin-bottom: 0 !important;
}

.dobCalander {
  position: relative;
  z-index: 10;
  top: -25px;
  width: 65%;
  box-shadow: 1px 2px 30px grey;
}

.option {
  margin-left: 20px;
  font-size: 12px;
  color: #2886af;
  text-transform: uppercase;
}

.option:hover {
  cursor: pointer;
}

.dropdown-menu::-webkit-scrollbar {
  width: 10px;
}
.dropdown-menu::-webkit-scrollbar-button {
  background: #fff;
}

.dropdown-menu::-webkit-scrollbar-track-piece {
  background: #fff;
}

.dropdown-menu::-webkit-scrollbar-thumb {
  background-color: grey !important;
}

.selectLabel {
  color: #939393;
  font-size: 14px;
  margin-bottom: 5px;
}

.blood-group-field .dropdown,
.weight-field .dropdown,
.head-circum-field .dropdown,
.cm-mm-view .dropdown {
  width: 38%;
  display: inline-block;
  margin-right: 20px;
}

.blood-group-field .dropMenu,
.weight-field .dropMenu,
.head-circum-field .dropMenu {
  width: 100%;
}

.blood-group-field .dropdownLabel::after,
.weight-field .dropdownLabel::after,
.head-circum-field .dropdownLabel::after {
  right: 0;
}

.vitalDetails .dropdownLabel {
  color: #000;
  font-size: 16px;
}

.feetinch-view .dropdown {
  width: 30%;
  display: inline-block;
  margin-right: 20px;
}

.feetinch-view .option {
  margin-left: 0;
}

.actionButtons .dropdown {
  display: inline-block;
}

.actionButtons .dropdown-menu {
  width: unset;
}

.errorInput {
  font-size: 14px;
  color: red;
}

/* .pd-tabs{
    
} */

.pd-tabs div {
  display: inline-block;
  width: 33.3%;
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 15px 0;
}

.pd-tabs div:hover {
  cursor: pointer;
}

.activeDetails {
  background-color: aliceblue;
  font-family: ProximaNova-Semibold;
  color: #2886af; 
   border-bottom: 3px solid #2886af !important;
}

.commingSoon {
  margin-top: 20px;
  /* padding:20px 0; */
  text-align: center;
  font-size: 24px;
}

.commingSoon > img {
  width: 250px;
}

.scannedrx {
  border: 1px solid #3a87b366;
  text-align: center;
  color: #3a87b3;
}
