.services {
    width: 50%;
    padding: 25px 8%;

}

.servicesTitle {
    font-family: ProximaNova-Semibold;
    font-size: 18px;
}

.rupees {
    font-size: 16px;
    color: #2886af;
    font-family: ProximaNova-Semibold;
    margin-right: 2px;
}

.payment {
    font-size: 16px;
    color: #2886af;
    font-family: ProximaNova-Semibold;
    margin-right: 2px;
}

.hyphen {
    color: #818181;
    font-size: 16px;
    font-family: ProximaNova-Regular;
    margin-right: 2px;
}

.service {
    height: 50vh;
    overflow: auto;
    width: 100%;
    margin-top: 2rem;

}

.shiftLoader img {
    width: 50px;
}

.shiftLoader {
    text-align: center;
}

.consult {
    height: 30px;
    /* display: flex; */
    font-family: ProximaNova-Semibold;
    font-size: 30px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: -0.94px;
    text-align: left;
    color: #000;
    position: relative
}

.vaccine {
    width: 142px;
    height: 30px;
    font-family: ProximaNova-Semibold;
    font-size: 30px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: -0.94px;
    text-align: left;
    color: #000;
    display: flex;
    position: relative;

}

.icons {
    font-size: 16px;
    margin: 3px;

}

.iconPrice {
    font-size: 14px;
    margin: 3px;

}

.treatment {

    font-family: ProximaNova-Semibold;
    font-size: 30px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: -0.94px;
    text-align: left;
    color: #000;
    position: relative;
}

.Rectangle {
    width: auto;
    margin-top: 2rem;
    position: relative;
}

.consultationButton1 {

    border-radius: 20px;
    text-align: center;
    background-color: #f7f7f7;
    border: 1px solid #d6d6d6;
    margin-right: 3%;
    height: 40px;
    padding-left: 2%;
    padding-right: 2%;
    border: none;
}

.consultationButton1:focus {
    outline: none;
}

.consultationButton2 {
    border-radius: 20px;
    text-align: center;
    margin-right: 3%;
    height: 40px;
    padding-left: 2%;
    padding-right: 2%;
    background-color: #2886af !important;
    color: white;
    border: none;
}

.consultationButton2:focus {
    outline: none;
}

.gridColumn3 {
    grid-column-start: 5;
    grid-column-end: 6;
    grid-row: 1;
    border-radius: 20px;
    /* margin: 0 38px 0 28px;
    padding: 7.6px 10.1px 9.4px 11.5px; */
}

.gridColumn3Disbale {
    grid-column-start: 5;
    grid-column-end: 6;
    grid-row: 1;
    border-radius: 20px;
    cursor: not-allowed;
}

.disabledButtonAdd {
    cursor: not-allowed;
    display: grid;
    padding: 5px;
    width: 100px;
    border-radius: 20px;
    border: 1px;
    background-color: #cccccc;
    justify-items: center;
    align-content: center;
    color: #666666;
    grid-template-columns: repeat(3, 1fr);
}


.increment {

    display: grid;
    padding: 5px;
    width: 100px;
    border-radius: 20px;
    border: 1px;
    border-style: solid;
    border-color: #2886af;
    background-color: white;
    justify-items: center;
    align-content: center;
    grid-template-columns: repeat(3, 1fr);
}

.increment:focus {
    outline: none;
}


.count {
    width: 33px;
    height: 20px;
    margin: 5px 0 5px 10px;
    font-family: ProximaNova-Semibold;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: -0.63px;
    text-align: left;
    color: #939393;
}

.disabledMinus {
    cursor: not-allowed;
    border: none;
    text-align: center;
    pointer-events: none;
    filter: grayscale(100%) brightness(1.0);
    transition: filter 0.3s ease;

}

.minusBtn {
    color: #2886af;
    border: none;
    cursor: pointer;
    background-color: white;
    text-align: center;
    padding: 10px
}

/* .disabledButton1 {
   cursor: not-allowed;
   display: grid;
   padding: 5px;
   width: 100px;
   border-radius: 20px;
   border: 1px;
   border-style: solid;
   border-color: #2886af;
   background-color: white;
   justify-items: center;
   align-content: center;
   grid-template-columns: repeat(3, 1fr);
} */

.disabledPlus {
    cursor: not-allowed;
    text-align: center;
    border: none;
    pointer-events: none;
    background-color: #cccccc;
    filter: grayscale(100%) brightness(1.0);
    transition: filter 0.3s ease;
}

.plusBtn {
    text-align: center;
    color: #2886af;
    border: none;
    background-color: white;
    cursor: pointer;
    padding: 5px
}

.counter {
    color: #2886af;
    text-align: center;

}

.disableCounter {
    color: #666666
}

.disableAddService {

    cursor: not-allowed;
    padding: 5px;
    border-radius: 20px;
    width: 100px;
    text-align: center;
    color: #666666;
    border: 1px;
    /* border-style: solid; */
    border-color: #999999;
    background-color: #cccccc
}


.addServiceBtn {

    padding: 5px;
    border-radius: 20px;
    width: 100px;
    text-align: center;
    color: #2886af;
    border: 1px;
    border-style: solid;
    border-color: #2886af;
    background-color: white;
}

.addServiceBtn:focus {
    outline: none;
}



.showTotalPrice {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    height: 70px;
    background-color: #f2f7fa;
    width: 100%;
    border-bottom: solid 1px #c4c0c0;
    align-items: center;
    justify-items: center;

}

.countService {
    font-size: 25px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    color: #000;
    grid-column-start: 1;
    grid-column-end: 3;
    margin-left: 0;
}

.showServiceCount {
    left: 5;
    grid-column-start: 1;
    grid-column-end: 3;
    font-size: 15px;
    font-weight: 0;

}

.totalServiceCount {
    margin-left: 5px;
    align-items: end;

}

/* .container {
  height:80vh;
} */

p {
    max-width: 25ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

}

.totalServicePrice {
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    color: #000;
    grid-column-start: 5;
    grid-column-end: 6;
    position: relative;
}



.doctorsService {

    margin-top: 1rem;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    /* grid-template-rows: auto; */
    /* overflow: auto; */

}

.path {
    margin-top: 12px;
    margin-bottom: 8px;
    width: 98%;
    height: 0;
    border: solid 1px #d0d0d0;
    grid-column-start: 1;
    grid-column-end: 6;
    grid-row-start: 2;

}


.displayService {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row: 1;
    font-family: ProximaNova-Semibold;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    color: #000;
    justify-items: center;
    max-width: 40ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 9px;
}

.displayService::first-letter {
    text-transform: capitalize;
}

.displayService:hover {
    white-space: normal;
    word-wrap: break-word;
    max-width: fit-content;
}

.removeButton{
    display: none;
}

.displayPrice {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row: 1;
    justify-content: left;
    align-items: left;
    margin-top: 9px;
    height: 18px;
    font-family: ProximaNova-Semibold;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: left;
    color: #000
}

.disabledButton {
    cursor: not-allowed;
}


.amounts {
    flex: 1;
    display: flex;
    flex-flow: column nowrap;
    background-color: #f3f3f3;
}

.payableAmountSection {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding: 40px 75px 40px 50px; */
    border-bottom: 2px solid rgba(0, 0, 0, 0.06);
    color: #575757;
    font-size: 1.5rem;
    font-family: ProximaNova-Semibold;
    width: 70%;
    margin: auto;

}

.rupeeIcon {
    margin-right: 6.2px;
    font-size: 22px;
    color: #2886af;
    font-family: ProximaNova-Bold;

}

.payableAmountFigure {
    font-size: 37px;
    color: #2886af;
    font-family: ProximaNova-Bold;
    display: inline-block;
    width: 100px;
    text-align: left;
}

.paidAmountSection {
    width: 70%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    flex: 1;
    align-items: center;
    padding: 15px 0;
}

/* .paidAmountSection>span,
.paidAmountSection>div{
} */
.paidAmountLabel {
    color: #2e2e2e;
    font-size: 1.5rem;
    font-family: ProximaNova-Bold;
}

.noService{
    font-family: ProximaNova-Bold;
    margin-top: 6rem;
    text-align: center;
}


