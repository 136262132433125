/* Header styling */
.header {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  position: fixed;
  width: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  z-index: 20;
 
 
}

.header a {
  text-decoration: none;
}

/* .container{
    height: 80px;
    margin-left: 18px;
    margin-right: 18px;
   
  }  */

.headerContainer {
  display: flex;
  /* flex-direction: row; */
  justify-content: space-between;
  align-items: center;
  /* flex-wrap: nowrap; */
  height: 65px;
  padding: 30px 18px;
  /* margin-left: 18px; */
  /* margin-right: 18px; */
}

.nav-dashboard-item {
  display: flex;
}

.item1-drop {
  display: flex;
}
.item1-drop:hover {
  cursor: pointer;
}

.logo {
  /* margin-left: 41px; */
  flex-grow: 4;
}

.searchInput::placeholder {
  color: #d5d5d5;
  font-size: 16px;
}

.searchInput {
  font-family: ProximaNova-Regular;
  border: 2px solid #cecece78;
  padding: 6px;
  width: 426px;
  border-radius: 30px;
  margin-right: 43px;
  text-indent: 3.5em;
  font-size: 18px;
  background: url(../../assets/images/icons/search-icon.svg) no-repeat 6%;
  background-size: 17px;
}

.searchInput:focus {
  outline: 0;
}

.bell-icon {
  padding: 25px;
  border-left: 1px solid #ebebeb;
  position: relative;
}

.bell-icon img {
  width: 24px;
  height: 24px;
}

.notification-count {
  display: inline-block;
  width: 18px;
  height: 18px;
  font-size: 11px;
  background: #fa5252;
  color: #ffffff;
  position: absolute;
  top: 22px;
  right: 19px;
  padding: 3px;
  border-radius: 50%;
  font-family: ProximaNova-Semibold;
  /* font-weight: bold; */
  text-align: center;
}

.settings-icon {
  padding: 25px;
  border-left: 1px solid #ebebeb;
  border-right: 1px solid #ebebeb;
}

.settings-icon img {
  width: 24px;
  height: 24px;
}

.logout-card {
  border-left: 1px solid #ebebeb;
}

.logout-image {
  /* padding-left: 20px; */
  padding-right: 20px;
}

.logout-image img {
  width: 37px;
  height: 37px;
}

.logout-content .user-name {
  margin: 0;
  /* width: 114px; */
  line-height: 1;
  font-size: 16px;
  font-family: ProximaNova-Semibold;
  text-transform: capitalize;
  /* font-weight: 600; */
  color: #000000;
}

.logout-content .specialization {
  margin: 0;
  font-size: 14px;
  font-family: ProximaNova-Regular;
  color: #777777;
  float: left;
  text-transform: capitalize;
}

.logout-content img {
  width: 10px;
  height: 10px;
  margin-left: 21.5px;
  margin-right: 21.5px;
}

.downIcon {
  display: inline-block;
  border-top: 7px solid #0000006b;
  border-left: 7px solid transparent;
  width: 10px;
  border-right: 7px solid transparent;
  height: 0px;
}
