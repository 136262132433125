.alldoc-dropdown{
    display:flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #ececed;
    border-bottom: 1px solid #ececed;
    background-color: #ffffff;
    padding:15px 10px;
    border-left:8px solid #fff;
  }
  .alldoc-dropdown:hover{
      cursor:pointer;
  }

  .alldoc-dropdown .doc-profile{
      display:flex;
      align-items: center;
  }
