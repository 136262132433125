/* Login screen container */
.loginScreenContainer{
    background: url('../../assets/images/LoginBackground.png') no-repeat;
    background-size: cover;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;    
}
.spinnerContainer {
    position: fixed;
     top: 50%;
    left: 50%; 
    transform: translate(-50%, -50%);
  }  
  .spinnerContainer img {
    width: 100px;
  }
  .shiftLoader.blurBackground {
    filter: blur(2px);
    pointer-events: none;
  }