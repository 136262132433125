/*Appointment Summary*/

.appointmentSummary {
  margin: 0 7px;
  width: 25%;
  /* display: flex;
  flex-direction: column;
  justify-content: space-between; 
  /* overflow: auto; */
  height: 600px;
  border-radius: 10px 10px 18px 18px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  /* overflow: hidden; */
}

.appointmentSummary .summaryTitle {
  position: relative;
  height: 70px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  display: flex;

  align-items: center;
  font-size: 18px;
  color: #3b3b3b;
  font-family: ProximaNova-Semibold;
  padding-left: 25px;
}

.apptSummaryCard {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.apptSummaryInnercards {
  flex-basis: 80%;
}

.apptSummaryInnercards .chooseData {
  padding: 0 20px;
}

.innerCard {
  display: flex;
  align-items: center;
  padding: 0 20px;
  border-bottom: 1.5px solid #cececeb5;
  height: 93px;
}

.apptSummarySeperator {
  background: #cecece;
  height: 1.5px;
  width: 100%;
}

.makeAppt-Btn {
  display: block;
  width: 85%;
  margin: auto;
  font-size: 85%;

  /* margin-bottom: 10px; */
  text-align: center;
  padding: 10px 20px;
  border-radius: 25px;
  text-transform: uppercase;
  font-family: ProximanOva-Semibold;
  margin-bottom: 80px;
  color: #fff;
  background-color: #2886af;
}

.makeAppt-Btn:disabled {
  opacity: 0.4;
}

.activeCat {
  border: 1px solid #2886af;
}