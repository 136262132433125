
@font-face {
  font-family: ProximaNova-Regular;
  src: url('./assets/fonts/ProximaNova-Regular.otf');
}

@font-face {
  font-family: ProximaNova-Semibold;
  src: url('./assets/fonts/ProximaNova-Semibold.otf');
}

@font-face {
  font-family: ProximaNova-Bold;
  src: url('./assets/fonts/ProximaNova-Bold.ttf');
}

@font-face {
  font-family: ProximaNova-Light;
  src: url('./assets/fonts/ProximaNova-Light.otf');
}

@font-face {
  font-family: ProximaNova-ExtraBold;
  src: url('./assets/fonts/ProximaNovaExtraBold.ttf');
}

html, body {
  margin: 0;
  padding: 0;
  height:100%;
  font-family:ProximaNova-Regular;
  overflow-y:auto;
  height:100%;
 
}

body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

h1, h2, h3, p {
  margin:0;
}

.clear{
  clear: both;
}

.right{
  float: right;
}

.left{
  float: left;
}

.hide{
  display:none;
}

.visible{
  display: block !important;
}

.padSide-10{
  padding:0 10px;
}

.padRight-5{
  padding-right:5px
}

.container {
  min-height:100%;
  background-color: #f7f7f7;
  max-width:1280px !important;
}

/*Reception Page*/

.dashboard{
  min-height:700px;
  background-color: #f7f7f7;
  padding:20px 0;
}

.dashboardContainer{
    display:flex;
    
}

.dashboardContainer .col1{
  width:297px;
}

.dashboardContainer .col2{
  margin-left: 1%;
  width: 75%;
  min-height:700px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
  /* flex: 80%; */
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
}

/*Utility Classes*/
.mar-80{
  margin-top:66px;
}

.monthCalender{
  display: flex;
  flex-wrap: wrap;
  justify-content:flex-end;
}

.monthCalender >div{
  width:14.28%;
}

button.react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day{
  border: 1px solid #3a87b3;
  color:#3a87b3
}

button.react-calendar__tile.react-calendar__month-view__days__day:disabled{
  opacity: 0.5;
}

.box{
  /* margin: 20px; */
  padding: 10px;
  /* width: 190px; */
  /* height: 124px; */
  border: 1px solid #ececed;
  background-color: #ffffff;
}

.box-items{
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  align-items: center;
  align-content: space-between;
}

.bullets{
  display: inline-block;
  width: 8px;
  height: 8px;
  opacity: 0.5;
  border-radius: 50%;
}

.blue{
  background-color: #2886af;
}

.grey{
  background-color: #b2b2b2;
}

.backgroundRed{
  background-color: red;
}

.number{
  font-family: ProximaNova-Semibold;
  color: #3680a1;
  font-size: 16px;
  line-height: 1;
  margin-bottom: 10px;
  text-align: left;
}

.status{
  display: inline-block;
  margin-left: 7px;
  margin-right: 7px;
  text-align: left;
  font-family: ProximaNova-Regular;
  font-size: 12px;
  color: #a2a2a2;
}

.count{
  font-family:ProximaNova-Semibold;
  font-size:12px;
  color: #a2a2a2;
}

/*Calender Header*/
.calender-header{
  display: flex;
  height: 70px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding:0 24px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.full-date{
  display:flex;
}

.date{
  float: left;
  font-size: 30px;
  font-family: ProximaNova-Bold;
  color: #005c84;
}

.day-month-year{
  padding:0 10px;
}

.right-content{
  display:flex;
  align-items: center;
}

.day{
  padding: 4px 0;
  font-size: 13px;
  text-align: left;
  color: #09163c;
  font-family: ProximaNova-Semibold;
}

.month-year{
  font-size: 13px;
  font-family: ProximaNova-Regular;
  text-align: left;
  color: #808080;
}

.timeCategory{
  font-family: ProximaNova-Semibold;
  font-size: 12px;
  padding: 0 7px;
  color: #2886af;
}

.month-wise img{
  height: 9px;
  margin-bottom: 3px;
  margin-right: 4px;
}

.category{
  padding:0 30px;
  font-size:12px;    
  font-family:ProximaNova-Semibold;
  text-align:left;
  color:#000000;
}

.switch{
  position: relative;
  display: inline-block;
  width: 62px;
  height: 34px;
  font-size: 13px;
  color: #ffffff;
  text-align: center;
}

.switch input{
  opacity: 0;
  width: 0;
  height: 0;
}

.slider{
  position: absolute;
  cursor: pointer;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before{
  position: absolute;
  content: "IN";
  height: 26px;
  width: 37px;
  left: 4px;
  bottom: 4px;
  background-color: #2886af;
  -webkit-transition: .4s;
  transition: .4s;    
}

input:checked + .slider{
  background-color: #ffffff;
}

input:focus + .slider{
  box-shadow: 0 0 1px #ffffff;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  transform: translateX(16px);
}

.slider.round{
  border-radius: 34px;
  border: 1px solid #2886af;
}

.slider.round:before{
  border-radius: 25px
}

.dayColumn{
  text-align:center;
  padding:25px 0 10px 0;
  text-transform: uppercase;
  color:#8b8b8b;
  font-family: ProximaNova-Regular;
  font-size: 14px;
}

/*Calender Change View*/
/* Main container */

/* .calender-view-container{
    display: flex;
    padding: 20px 40px;
    align-items: center;        
}

.calender-view-container>div{
  flex:1;
} */

/* Appointments text styling  */

.show-appointments{
    font-family: ProximaNova-Semibold; 
    color: #4e4e4e;
    font-size: 16px;
}

.view-tab{
    display: flex;
    align-items: center;
    justify-content: center;
}

.ecllipse-tab{
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.06);
    padding: 8px 15px;
    border-radius: 30px;
}

.month-year-text{
    padding: 0 20px;
    font-size: 12px;
    color: #4e4e4e;
    text-align: center;
    font-family: ProximaNova-Semibold;
}

.react-calendar{
      width:100% !important;
      background: white;
      border: none !important;
      font-family: ProximaNova-Regular;
      line-height: 1.125em;
      border-radius: 10px;
      margin-bottom: 14px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
      padding:10px
}

.react-calendar__tile{
  
  border-radius: 50% !important;
}

.react-calendar__month-view__days{
  justify-content: space-between;
}

.react-calendar__month-view__weekdays{
  padding-bottom:15px;
  border-bottom:1px solid #ececed;
  margin-bottom:5px;
}

.react-calendar__month-view__weekdays__weekday{
  text-transform: capitalize;
  color:#828282;
}

.react-calendar button{
  font-size:12px;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button{
  display:none
}

button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button{
  display:none;
}
.react-calendar__navigation button[disabled] {
  background: transparent !important;
}

.react-calendar__tile--active{
  background:#3a87b3 !important;
  color:#fff !important;
}

.toast{
  font-size: 1rem;
  background-color: rgba(4, 3, 3, 0.54);
  color: #fff;
  border-radius: 25px;
}

.castIcon {
  width:25px;
  margin-right:30px;
}

.castIcon:hover{
  cursor: pointer;
}




