.selectDoctor {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  /* overflow-y: auto; */
  height: 100%;
  border-radius: 10px;
  /* overflow-y: auto; */
}

/*Calendert Head*/

.doctorList {
  display: flex;
  /* justify-content: space-between; */
  /* margin: 35px 20px; */
  justify-content: baseline;
  overflow: auto;
  padding: 45px 0;
  height: 95%;
  flex-wrap: wrap;
}

/* responsiveness */

@media screen and (max-width: 480px) {
  .selectDoctor {
    width: fit-content;
  }
}
