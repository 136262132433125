.availableSlots {
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: visible;
  /* height:80vh; */
}

.availableSlots .slotDate {
  position: relative;
  width: 20%;
  box-shadow: 2px 5px 25px 0 rgba(0, 0, 0, 0.16);
  /* height: 600px; */
  z-index: 8;
  background-color: #fff;
}

.availableSlots .slotDate h3 {
  font-size: 14px;
}

.availableSlots .slotShifts {
  width: 30%;
  position: relative;
  background: white;
  box-shadow: 10px 5px 20px 0 rgba(0, 0, 0, 0.08);
  /* height: 600px; */
  z-index: 7;
}

.availableSlots .slotTime {
  width: 50%;
  box-shadow: 10px 5px 20px 0 rgba(0, 0, 0, 0.12);
  /* height: px; */
  background-color: #fff;
}

.availableSlots .slotShifts > div {
  height: 100%;
  padding: 0 15px;
  overflow: hidden;
}

.availableSlots .slotShifts h3 {
  font-size: 14px;
}

.availableSlots .slotTime > div {
  /* padding: 20px; */
  padding: 0 20px;
  height: 100%;
  overflow: auto;
  margin-top: 40px;
  padding-bottom: 150px;
}

.availableSlots .slotTime h3 {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}

.availableSlots .slotTime h4 {
  padding: 0 18px;
  font-size: 16px;
  font-family: ProximaNova-Semibold;
}

/* Select Date */
.selectDate {
  color: #2886af;
  font-family: ProximaNova-Semibold;
  font-size: 75%;
  line-height: 1.3;
  text-transform: uppercase;
}

/* Select Day */
.selectDay {
  color: #939393;
  font-family: ProximaNova-Semibold;
  font-size: 12px;
}

.colorWhite {
  color: #fff;
}

/*Date Card*/

.selectDateCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #ebebeb;
  height: 68px;
}

.selectDateCard:hover {
  cursor: pointer;
  background-color: #2e8aaf0f;
}

.activeDate,
.shiftActive,
.activeSlot > p:hover {
  transition: all 0.3s ease-in;
  background-color: #2886af !important;
  color: #fff !important;
  cursor: pointer;
}

/*Select Shift*/

.selectShiftCard {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  /* height: 100px; */
  border-bottom: 1px solid rgba(0, 0, 0, 0.14);
  padding: 20px 0 30px 0;
}

/* Select shift time */
.shiftTime {
  color: #000000;
  font-family: ProximaNova-Semibold;
  font-size: 15px;
  text-transform: capitalize;
  line-height: 1.9;
  padding: 10px 0;
}

/* Select shift slot */

.shiftSlot {
  color: #2886af;
  margin-bottom: 20px;
  border: 1px solid #d6d6d6;
  border-radius: 25px;
  text-align: center;
  font-size: 70%;
  width: 85%;
  padding: 8px 0;
  text-transform: uppercase;
  font-family: ProximaNova-Semibold;
}
.activeShift {
  background-color: #2886af !important;
  color: #fff !important;
  cursor: pointer !important;
}

.shiftSlot:hover {
  background-color: #2e8aaf0f;
  color: #2886af;
  cursor: pointer;
}


.slotsRow {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 28px 0;
}

.slotsRow .slot {
  width: 25%;
  /* margin:0 0.5%; */
  text-align: center;
}

.slot > p {
  width: 90%;
  display: inline-block;
  padding: 6px 7px;
  font-size: 63%;
  color: #1396cf;
  border: 1px solid #d6d6d6;
  border-radius: 25px;
  margin: 8px 0;
}

.slotBooked > p {
  background-color: #ebebeb;
  color: #939393;
  border: none;
}

.slotInactive > p {
  color: #939393;
}

.slotTimeHeading {
  padding: 17px 20px;
  background-color: #f1f1f1;
  /* margin-bottom: 20px; */
}

.shiftLoader {
  text-align: center;
}

.shiftLoader img {
  width: 50px;
}

.slotBtn {
  background: none;
  border: 0;
}

.createAppointment div::-webkit-scrollbar {
  width: 7px;
}
.createAppointment div::-webkit-scrollbar-button {
  background: #ebebeb;
  display: none;
}

.createAppointment div::-webkit-scrollbar-track-piece {
  background: transparent;
}

.createAppointment div::-webkit-scrollbar-thumb {
  background-color: #f1f1f1;
}

.addslot-btn-container {
  text-align: center;
  margin-top: 35px;
  position: relative;
  z-index: 100;
}

.addslot-btn {
  margin: auto;
  border-radius: 25px;
  border: 1px solid #2e8aaf;
  color: #2e8aaf;
  padding: 5px 0;
  width: 129px;
}
.addslot-btn:hover {
  cursor: pointer;
  transition: all 0.6s ease-in;
  background-color: #2e8aaf;
  color: #fff;
  transition: all 0.4s ease-in;
}

.slotNumber {
  text-align: center;
  margin: 6px;
  width: 34px;
  padding: 3px !important;
  border-radius: 10px;
  border-color: #bababa;
  color: #000;
}

.focusSlot {
  background-color: #2e8aaf;
  color: #fff;
}

.addSlotModalBtn {
  border-radius: 25px;
  padding: 5px 22px;
  font-size: 14px;
}

.addSlotModalBtn:disabled {
  border-color: #808080ba;
  color: #808080ba;
}

.addSlotModal .modal-content {
  border-radius: 10px;
}

.addSlotModal .modal-footer {
  border-top: none;
}

.addSlotModalcontentDesc {
  font-size: 14px;
  padding: 10px 15px;
  text-align: left;
}

.noShiftExist {
  padding: 35px 20px;
  text-align: center;
  font-family: ProximaNova-Semibold;
}

.noSlotExist {
  margin: auto;
  padding: 15px 0;
  text-align: center;
  font-family: ProximaNova-Semibold;
}

.settingSlotModal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100%;
  background-color: #000;
  color: white;
  opacity: 0.7;
  left: 0;
  top: 0;
  font-size: 20px;
  z-index: 15;
  padding-top: 111px;
  width: 100%;
}

.slotsInThisHour{
  margin-top: 2.5rem;
}
