.invoiceSeparator{
    width: inherit;
    border: 1px solid #70707017;
}

/* Styling Main Container */

.invoiceDetailsContainer{
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #707070a1;
    background-color:#fff;
    margin-bottom:25px;
}

/* Styling First Row */

.invoiceBasicDetails{
    height: 45px;
    padding:  0 20px;
    display: flex;
    align-items: center;
    font-size: 14px;
}

.invoiceBasic{
    display: flex;
    align-items: center;
    flex: 1;
}

/* .invoiceCheckbox{
    width: 18px;
    height: 18px;
    border: 1px solid #bcbcbc;
    border-radius: 3px;
    background-color: #ffffff;
} */

.invoiceCheckbox{
    position: relative;
    width: 16px;
    height: 16px;
    display: inline-block;
}

.invoiceCheckbox input{
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
    margin: 0;
    /* border: 1px solid #bcbcbc; */
    /* background-color: #ffffff; */
    border-radius: 3px;
    cursor: pointer;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 14px;
    width: 14px;
    /* background-color: #ffffff; */
    border: 1px solid #bcbcbc;
    border-radius: 3px;
    cursor: pointer;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.invoiceCheckbox input:checked ~ .checkmark:after {
   display: block;
}

/* Style the checkmark/indicator */
.invoiceCheckbox .checkmark:after {
    left: 5px;
    top: 0px;
    width: 3px;
    height: 9px;
    border: solid #2886af;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
    
.invoiceLabel, .hyphen{
    font-family: ProximaNova-Semibold;
}

.invoiceLabel{
    padding: 0 0 0 15px;
}

.hyphen{
    padding: 0 5px;
}

.invoiceNumber{
    padding: 0 7px;
    color:  #939393;
    font-family: ProximaNova-Regular;

}

.invoiceDateTime{
    display: flex;
    align-items: center;
    
}

.invoiceDate, .invoiceTime{
    display: flex;
    align-items: center;
}

.invoiceDate{
    padding: 0 40px;
}

.date{
    font-size: 14px;
    font-family: ProximaNova-Semibold;
}
.calenderIcon, .clockIcon{
    padding: 0 10px;
    display: flex;
}

.invoiceDescription{
    border-top: 1px dashed #7070703d;
    border-bottom: 1px dashed #7070703d;
    display: flex;
    align-items: center;
    height: 130px;
}

.invoiceModeServices{
    flex: 2;
    padding: 0 40px;
    display: flex;
    flex-direction: column;
}

.invoiceServices{
    display: flex;
    font-size: 14px;
    padding: 10px 0 0 0;
}

.editInvoiceButton{
    flex: 1;
    padding: 0 40px;
    display: flex;
    justify-content: flex-end;
}   

.editButton{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.editInvoiceIcon{
    width: 25px;
    height: 25px;
}

.editInvoiceIcon img{
    width: 100%;
}

.mode{
    font-size: 16px;
}
.modeLabel, .modeName{
    font-family: ProximaNova-Regular;
}

.modeName, .serviceNames{
    color: #5c5c5c;
}

.modeLabel{
    padding: 0  0 0 11px;
}

.modeName{
    color: #5c5c5c;
    font-size: 14px;
    padding: 0 8px;
}

.serviceNames{
    padding: 0 12px;
    font-family: ProximaNova-Regular;
    color: #5c5c5c;
    font-size: 16px;
}

.moreServices a{
   font-family:  ProximaNova-Semibold;
   font-size: 14px;
   color: #468ec6;

}

.editInvoiceLabel{
    padding: 10px 0 0 0;
    color: #2886af;
    font-size: 14px;
    font-family: ProximaNova-Semibold; 
}

.invoicePayment{
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
}

.totalPayment{
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.totalPayment:after{
    content: "";
    border: 1px solid #7070701f;
    height: 30px;
    position: absolute;
    right: 0;
    bottom: 0;
}

.totalAmount{
    padding: 0 5px;
}

.totalPaymentAmt, .paidPayment{
    font-size: 16px;
    font-family: ProximaNova-Semibold;
    color: #434343;
}

.discount{
    color: #3e8e04;
    font-size: 14px;
    font-family: ProximaNova-Semibold;
    /* padding: 7px 0 0 0; */
}

.paidPayment{
    width: 25%;
    display: flex;
    justify-content: center;
    position: relative;
}

.paidPayment:after{
    content: "";
    border: 1px solid #7070701f;
    height: 30px;
    position: absolute;
    top: -9px;
    right: 0px;
    bottom: 0px;
}

.paidAmount{
    padding: 0 7px;
}

.advancePayment{
    width: 25%;
    display: flex;
    justify-content: center;
    font-size: 16px;
    color: #3e8e04;
    font-family: ProximaNova-Semibold;
    position: relative;
}

.advancePayment:after{
    content: "";
    border: 1px solid #7070701f;
    height: 30px;
    position: absolute;
    top: -9px;
    right: 0px;
    bottom: 0px;
}

.advanceAmount{
    padding: 0 7px;
}

.refundPaymentBtn{
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.refundLabel{
    font-family: ProximaNova-Semibold;
    font-size: 14px;
    color: #2886af;
    padding: 0 8px;
}