.apptListOptions{
    position: absolute;
}

.edit_payment_icon{
    width:30px;
}

.edit_payment_icon:hover{
    cursor: pointer;
}

.apptListAmount{
    padding-left:2%;
    font-size: 14px;
    color: green;
}

.editApptBtn{
    cursor:pointer;
}

/* .menuList{
    text-align: center;
    background: #2886af;
}
*/

/* .dropdown-menu{
    background-color: #2886af;
    text-align: center;
}

.printButton{
    cursor: pointer;
    text-align: center;
    color: #ffffff;
} 

.greyButton{
    color: #636363;
}

.printButton .greyButton:hover{
    color: #636363;
}

.printButton:hover{
    color: #ffffff;
    text-decoration: none;
} */

.printButton{
    color: #2886af;
    cursor: pointer;
    position: relative;

}

a.printButton:hover{
    color: #2886af;
    text-decoration: none;

}

.printButton:focus{
    background: transparent;
}

a.printButton:hover{
    color: #2886af;
    background: transparent;
    font-family: ProximaNova-Semibold;
}

.greyButton{
    color: #636363;
    cursor: pointer;
    position: relative;
}

a.greyButton:hover{
    color: #636363;
    cursor: pointer;
    text-decoration: none;
}

.printActiveMessage{
    font-size: 14px;
    font-family: ProximaNova-Regular;
    position: absolute;
    top: 50px;
    right: 0;
    color: #636363;
}

/* .printButton.greyButton>a:hover{
    color: #636363;
} */

.apptListContainer{
    position: relative;
}

.apptListContainer>div.printActiveMessage{
    width: unset;
}

.apptListContainer>div.apptTypeFlag{
    width: 20px;
    height: 25px;
    position: absolute;
    top: 0;
    left: 0;
    border-top: 30px solid #2886af;
    border-right: 25px solid transparent;
}

.apptTypeFlag span{
    color: #fff;
    font-family: ProximaNova-Semibold;
    font-size: 8px;
    top: -25px;
    left: 2px;
    position: absolute;
    transform: rotate(-52deg)
}


