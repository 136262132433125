.EditPayment {
    /* padding-top:20px; */
    background-color: #fff;
    min-height: 100%;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;

}

.editPaymentBtnGroup {
    /* text-align: center; */
    /* /* padding:35px 0; 
    margin:50px 0; */
    margin-top: 2rem;
    text-align: center;
}

.editPaymentBtnEdit {
    background-color: #1c96c6;
    padding: 10px;
    width: 150px;
    margin: 0 10px;

}

.editPaymentBtnReset:hover {
    background-color: initial;
    color: #1c96c6;
}

.editPaymentBtnReset {
    background-color: #fff;
    color: #1c96c6;
    padding: 10px;
    width: 150px;
    margin: 0 10px;
}